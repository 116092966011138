import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar: React.FC = () => {
  const location = useLocation();

  return (
    <nav className="bg-paper shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex justify-start">
            {/* <a 
              className="text-black cursor-pointer"
              href="mailto:joss-arias@outlook.com"
            >
              Contact
            </a> */}
          </div>
          <div className="flex flex-row justify-center space-x-4">
            <Link to="/" className={`${location.pathname === '/' ? 'border-b-2 border-black' : ''}`}>
              Home
            </Link>
            <Link to="/work" className={`${location.pathname === '/work' ? 'border-b-2 border-black' : ''}`}>
              Work
            </Link>
          </div>
          <div className="flex justify-end">
            <img src='/assets/logo.svg' alt="Logo" className="h-8" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;



