import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import Footer from '../Footer';

const ThomsonCarter: React.FC = () => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});
  const iconRefs = useRef<{ [key: string]: HTMLSpanElement | null }>({});
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  const toggleSection = (section: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    Object.entries(iconRefs.current).forEach(([title, ref]) => {
      if (ref) {
        gsap.to(ref, {
          rotation: openSections[title] ? 180 : 0,
          duration: 0.3,
          ease: 'power2.inOut',
        });
      }
    });
  }, [openSections]);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust this value based on your breakpoint for medium screens
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    videoRefs.current.forEach(video => {
      if (video) {
        if (isSmallScreen) {
          video.pause();
          video.removeAttribute('autoplay');
          video.controls = true; // Show controls on small screens
        } else {
          video.setAttribute('autoplay', '');
          video.controls = false; // Hide controls on medium/large screens
          video.play().catch(e => console.error('Error attempting to play video:', e));
        }
      }
    });
  }, [isSmallScreen]);

  return (
    <div className="bg-paper h-auto md:h-screen">
      <h1 className="text-subheading py-12 px-12 mx-8">FINESSE STUDIOS</h1>
      <div className="flex flex-col md:flex-row flex-1 h-full">
        {/* Right column */}
        <div className="md:w-1/3 mx-12 md:mx-20 flex flex-col justify-between order-1 md:order-2">
          {/* Project explanation */}
          <div className="p-1">
            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('Project Overview')}
              >
                Collaboration and Creative Initiative
                <span ref={(el) => (iconRefs.current['Project Overview'] = el)}>
                  {openSections['Project Overview'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['Project Overview'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  For this project, I collaborated with Finesse Studios as a freelance 3D Generalist, 
                  contributing to the creative vision and technical execution of a visualization project. 
                  In addition to assisting the team, I took the initiative to create my own concept piece 
                  to demonstrate innovative ideas, which inspired certain elements of the final output. 
                  The project required a blend of technical expertise and artistic direction, and I played 
                  a key role in creating character simulations, environment design, lighting setups, and 
                  final compositing.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('Design Approach')}
              >
                Realistic Character Simulations
                <span ref={(el) => (iconRefs.current['Design Approach'] = el)}>
                  {openSections['Design Approach'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['Design Approach'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  A significant part of the project involved simulating the character’s movement using 
                  Alembic files to ensure seamless animations. This allowed me to build realistic 
                  interactions between the animated avatar and the dress it wore. The dress design 
                  required careful manipulation of diffuse maps to define its base texture, as well as 
                  opacity maps to control the transparency of certain elements, ensuring that parts of 
                  the underlying avatar remained hidden as per the project guidelines. To enhance the 
                  garment's realism, I incorporated normal maps to introduce fine surface details and 
                  displacement maps to add subtle depth and dimensionality to the fabric.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('Technical Implementation')}
              >
                Lighting and Atmosphere Design
                <span ref={(el) => (iconRefs.current['Technical Implementation'] = el)}>
                  {openSections['Technical Implementation'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['Technical Implementation'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  To create the lighting and atmosphere, I employed a 3-point light setup in Blender, 
                  complemented by a sky texture to simulate a natural daylight ambiance. The lighting 
                  choices were pivotal in establishing a warm and realistic tone, ensuring the character 
                  and dress were illuminated dynamically while maintaining visual balance. I fine-tuned 
                  the intensity and placement of lights to highlight the intricate details of the fabric 
                  and enhance the overall composition.
                </p>
              </div>
            </div>
          </div>

          {/* Two images */}
          <div className="flex flex-col md:flex-row mb-6">
          <video 
            ref={el => videoRefs.current[0] = el}
            className="w-full h-full object-cover" 
            loop 
            muted 
            playsInline
          >
            <source src="/assets/Finesse.mp4" type="video/mp4" />
          </video>
          </div>

          {/* Internal Visualizations */}
          <div className="flex flex-col m-8">
            <div className="mb-4">
              <h2
                className=" text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('BottleDetails')}
              >
                Environment Modeling and Texturing
                <span ref={(el) => (iconRefs.current['BottleDetails'] = el)}>
                  {openSections['BottleDetails'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['BottleDetails'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  For the environment, I modeled key architectural elements using Blender’s modeling 
                  tools, adding realism by creating textures through Stable Diffusion to generate unique, 
                  organic wall textures. I extended this realism by crafting vegetation assets and 
                  scattering them across the scene using procedural techniques, creating a lush and 
                  natural backdrop. These elements worked harmoniously to support the character 
                  visualisation and ground the scene in a believable environment.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h2
                className="text-xl text-paragraph pb-2 pt-4 cursor-pointer flex justify-between items-center"
                onClick={() => toggleSection('AdvancedSimulations')}
              >
                Rendering and Post-Production
                <span ref={(el) => (iconRefs.current['AdvancedSimulations'] = el)}>
                  {openSections['AdvancedSimulations'] ? '−' : '+'}
                </span>
              </h2>
              <div className="h-30 overflow-hidden">
                <p
                  className={`text-small leading-relaxed transition-opacity duration-300 max-w-md ${
                    openSections['AdvancedSimulations'] ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  The rendering process was completed using Octane Render, which provided 
                  high-quality results with optimized efficiency. I utilized techniques like 
                  camera culling to reduce render times while maintaining the visual fidelity 
                  of the scene. Post-production was carried out in Adobe After Effects, where 
                  I composited the final renders, refined color grading, and added subtle 
                  visual effects to polish the output. The end result was a visually striking 
                  piece that merged technical precision with artistic creativity, aligning 
                  perfectly with Finesse Studios' vision.
                </p>
              </div>
            </div>
          </div>

          {/* Two small Images */}
          <div>
            <img src="/assets/3Still.jpg" alt="Image 1" className="w-full object-cover mt-4" />
            <img src="/assets/4Still.jpg" alt="Image 2" className="w-full object-cover mt-4" />
          </div>
        </div>

        {/* Right column: Vertical video */}
        <div className="md:w-1/2 mx-8 md:mx-0 px-0 order-2">
          <div>
            <img src="/assets/1Still.jpg" alt="Image 1" className="w-full object-cover mt-4" />
            <img src="/assets/2Still.jpg" alt="Image 2" className="w-full object-cover mt-4" />
          </div>
          {/* <video 
            ref={el => videoRefs.current[0] = el}
            className="w-full h-full object-cover" 
            loop 
            muted 
            playsInline
          >
            <source src="/assets/finesse.mp4" type="video/mp4" />
          </video> */}
          <div>
            <img src="/assets/6Still.jpg" alt="Image 1" className="w-full object-cover mt-4" />
          </div>
          <div>
            <img src="/assets/5Still.jpg" alt="Image 1" className="w-full object-cover mt-4" />
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default ThomsonCarter;