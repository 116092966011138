import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import Footer from '../components/Footer';

const HomePage: React.FC = () => {
  const [showMore, setShowMore] = useState(false);
  const workButtonRef = useRef<HTMLAnchorElement>(null);
  const aboutButtonRef = useRef<HTMLDivElement>(null);
  const aboutContentRef = useRef<HTMLDivElement>(null);
  const plusMinusRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (workButtonRef.current) {
      gsap.set(workButtonRef.current, { scale: 1 });

      workButtonRef.current.addEventListener('mouseenter', () => {
        gsap.to(workButtonRef.current, { scale: 1.5, duration: 0.3, ease: 'power2.out' });
      });

      workButtonRef.current.addEventListener('mouseleave', () => {
        gsap.to(workButtonRef.current, { scale: 1, duration: 0.3, ease: 'power2.out' });
      });
    }
  }, []);

  useEffect(() => {
    if (aboutContentRef.current) {
      gsap.to(aboutContentRef.current, { 
        height: showMore ? 'auto' : '0', 
        duration: 0.3, 
        ease: 'power2.out',
        onComplete: () => {
          gsap.set(aboutContentRef.current, { height: showMore ? 'auto' : '0' });
        }
      });
    }
  }, [showMore]);

  useEffect(() => {
    if (plusMinusRef.current) {
      gsap.to(plusMinusRef.current, {
        rotation: showMore ? 180 : 0,
        duration: 0.3,
        ease: 'power2.out'
      });
    }
  }, [showMore]);

  return (
    <div className="relative min-h-screen flex flex-col">
      <div className="flex-grow flex flex-col md:flex-row mt-4 relative">
        {/* WebM Video */}
        <div className="order-last md:order-none md:absolute left-0 md:left-20 bottom-0 md:top-0 z-0 w-full md:w-auto">
          <img 
            src="/scene/logo.gif"
            alt="Logo animation"
            className="w-3/4 md:w-full h-auto object-contain mx-auto md:mx-0"
          />
        </div>

        <div className="w-full md:w-[400px] z-10 md:ml-auto order-1 md:order-2 mb-4 md:mb-0">
          <button
            onClick={() => setShowMore(!showMore)}
            className="bg-highlight text-black px-6 py-6 
            font-semibold text-subtitle text-left 
            w-full rounded-t-lg flex items-center"
          >
            About
            <span ref={plusMinusRef} className="ml-auto md:ml-[16rem] inline-block">
              {showMore ? '−' : '+'}
            </span>
          </button>
          <div 
            ref={aboutContentRef} 
            className="bg-highlight text-black px-6 py-0 
            rounded-b overflow-hidden"
            style={{ height: 0 }}
          >
            <p className="text-paragraph mt-2 pb-6">
              I am driven by a passion to create immersive and 
              innovative visual experiences that transform how 
              people perceive and interact with products online.
              I believe in making advertising more effective and 
              enhancing communication, allowing companies to reach 
              their right audience through playful and engaging 
              interactions.
            </p>
          </div>
        </div>
        <Link
          ref={workButtonRef}
          to="/work"
          className="inline-flex items-center justify-center bg-transparent 
          border-2 border-black rounded-[100px/50%]
          font-bold transition-all duration-200 
          overflow-hidden group text-subtitle
          md:absolute md:right-[22rem] md:top-12 z-20
          mx-auto md:mx-0 mt-4 md:mt-0 order-2 md:order-1"
          style={{ width: '200px', height: '50px' }}
        >
          <span className="relative z-10 text-darkYellow group-hover:text-white transition-colors duration-200">Work</span>
          <div className="absolute inset-0 bg-black
          transform -translate-y-full transition-transform 
          duration-300 group-hover:translate-y-0"></div>
        </Link>
        {/* "By Arias" Text */}
        <div className=" md:block absolute left-0 right-0 text-center 
                      bottom-40 md:bottom-0"> 
          <p className="text-small">
            By Arias
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

