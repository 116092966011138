import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

interface Project {
  id: number;
  title: string;
  image: string;
  path: string;
}

const projects: Project[] = [
  { id: 1, title: 'FINESSE STUDIOS (3D Generalist)', image: '/assets/1Still.jpg', path: '/finesse' },
  { id: 2, title: 'THOMSON CARTER (3D Product Visualisation & Motion Graphics)', image: '/assets/TC1.webp', path: '/thomson-carter' },
  { id: 3, title: 'NEBULOX (3D Design & Web Development)', image: '/assets/Open.jpg', path: '/nebulox' },
  { id: 4, title: 'POSTER DESIGN (3D Graphic Design)', image: '/assets/Poster-4.jpeg', path: '/poster-design' },
  { id: 5, title: 'WEB DESIGN (UX and Web Development)', image: '/assets/bow.webp', path: '/web-design' },
];

const WorkPage: React.FC = () => {
  const [hoveredProject, setHoveredProject] = useState<number | null>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const projectRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  useEffect(() => {
    if (imageRef.current) {
      if (hoveredProject !== null) {
        gsap.to(imageRef.current, {
          width: '33vw',
          height: '33vw',
          opacity: 1,
          x: '50%',
          y: '50%',
          xPercent: -50,
          yPercent: -50,
          duration: 0.6,
          ease: 'power2.out'
        });
      } else {
        gsap.to(imageRef.current, {
          opacity: 0,
          duration: 0.6,
          ease: 'power2.in'
        });
      } 
    }
  }, [hoveredProject]);

  return (
    <div className="flex items-center justify-center h-screen bg-paper relative overflow-hidden">
      <div className="flex flex-col text-center z-10">
        {projects.map((project, index) => {
          // Split the title into bold and regular parts
          const [boldText, rest] = project.title.split('('); // Splits at '('
          const regularText = rest ? `(${rest}` : ''; // Add the '(' back if there is text in parentheses

          return (
            <React.Fragment key={project.path}>
              <Link
                ref={(el) => (projectRefs.current[index] = el)}
                to={`/projects${project.path}`}
                className="text-xl text-darkYellow hover:text-white hover:bg-black 
                transition-colors duration-200 relative z-10 px-6"
                onMouseEnter={() => setHoveredProject(project.id)}
                onMouseLeave={() => setHoveredProject(null)}
              >
                <span className="font-bold">{boldText.trim()}</span> {regularText}
              </Link>
              {index < projects.length - 1 && <span className="text-darkYellow mx-2">•</span>}
            </React.Fragment>
          );
        })}
      </div>
      <div 
        ref={imageRef}
        className="fixed bg-center bg-cover pointer-events-none"
        style={{ 
          backgroundImage: hoveredProject !== null ? `url(${projects.find(p => p.id === hoveredProject)?.image})` : 'none',
          zIndex: 0 
        }}
      />
    </div>
  );
};

export default WorkPage;
