import React, { useState, useRef, useEffect, lazy, Suspense } from 'react';
import gsap from 'gsap';
import Footer from '../Footer';

const DropdownSection: React.FC<{ title: string; description: string }> = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const iconRef = useRef<HTMLSpanElement>(null);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (iconRef.current) {
      gsap.to(iconRef.current, {
        rotation: isOpen ? 180 : 0,
        duration: 0.3,
        ease: "power2.inOut"
      });
    }
  }, [isOpen]);

  return (
    <div>
      <h2 
        className="text-xl text-paragraph pb-4 pt-10 cursor-pointer 
        flex justify-between items-center mr-10"
        onClick={toggleSection}
      >
        {title}
        <span ref={iconRef}>{isOpen ? '−' : '+'}</span>
      </h2>
      <div className="md:h-40 sm:60 overflow-hidden"> {/* Fixed height container */}
        <div className={`text-small leading-relaxed
                  transition-opacity duration-300 max-w-md
                  ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const WebDesign: React.FC = () => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);

  useEffect(() => {
    // Hide scroll indicator after 3 seconds
    const timer = setTimeout(() => {
      setShowScrollIndicator(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="bg-paper">
      <h1 className="text-subheading mb-6">WEB DESIGN</h1>
      
      {/*Cafe Primitivo*/}
      <div className="flex flex-col md:flex-row md:mb-12 sm:mb-0 px-2">
        <div className="w-full md:w-1/2 flex flex-col justify-center">
          <DropdownSection 
            title="Café Primitivo" 
            description="I designed and implemented Café Primitivo's landing page on Wix, 
            focusing on creating a visually appealing, mobile-responsive design. The layout 
            emphasized the brand’s artisan identity, with SEO optimizations to boost online 
            visibility. I integrated animations and interactive elements to enhance the user 
            experience. The final design improved user engagement and showcased the brand’s 
            offerings effectively." 
          />
        </div>
        <div className="w-full md:w-1/2 mt-8 max-h-[500px] overflow-y-auto relative scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <img src="/assets/cafePrimitivo.webp" alt="Image 1" className="w-full object-cover" loading="lazy" />
        </div>
      </div>

      {/*Bow Wow*/}    

      <div className="flex flex-col md:flex-row-reverse mb-12">
        <div className="w-full md:w-1/2 p-6 flex flex-col justify-center">
          <DropdownSection 
            title="Bow Wow International" 
            description="I collaborated with Bow Wow International's design team to update 
            their landing page for Team Ikuzawa in a React application. Using brand 
            guidelines, I implemented new UI elements, optimized performance, and added 
            interactive features like hover effects. The updates improved user experience 
            while maintaining consistency with the branding guidelines"
          />
        </div>
    
        <div className="w-full md:w-1/2 mt-8 max-h-[500px] overflow-y-auto relative scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <img src="/assets/bow.webp" alt="Image 2" className="w-full object-cover" loading="lazy" />
          
        </div>
      </div>

      {/*HyperionDev International*/}
      <div className="flex flex-col md:flex-row md:mb-12 sm:mb-0 px-2">
        <div className="w-full md:w-1/2 flex flex-col justify-center">
          <DropdownSection 
            title="HyperionDev" 
            description="I worked with HyperionDev’s development team to implement a 
            React.js page promoting bootcamp offers. The page featured reusable components, 
            responsive design, and SEO enhancements. I ensured the layout effectively 
            highlighted courses and pricing, providing a user-friendly interface aligned 
            with the company’s goals." 
          />
        </div>
        <div className="w-full md:w-1/2 mt-8 max-h-[500px] overflow-y-auto relative scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <img src="/assets/hyperionDev.webp" alt="Image 1" className="w-full object-cover" loading="lazy" />
          
        </div>
      </div>

      {/*Muzz*/}

      <div className="flex flex-col md:flex-row-reverse mb-12">
        <div className="w-full md:w-1/2 p-6 flex flex-col justify-center">
          <DropdownSection 
            title="Muzz" 
            description="I collaborated with Muzz’s design team to implement UI updates in 
            their React application. This included refining navigation, layouts, and 
            responsiveness. I optimized performance by adding lazy loading for components 
            and ensured smooth functionality across devices. The changes enhanced user 
            satisfaction and reinforced brand identity." 
          />
        </div>
        <div className="w-full md:w-1/2 mt-8 max-h-[500px] overflow-y-auto relative scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <img src="/assets/muzz.png" alt="Image 2" className="w-full object-cover" loading="lazy" />
          
        </div>
      </div>

      {/*Fold*/}
      <div className="flex flex-col md:flex-row md:mb-12 sm:mb-0 px-2">
        <div className="w-full md:w-1/2 flex flex-col justify-center">
          <DropdownSection 
            title="Fold" 
            description="I designed and built a page for Fold using Squarespace, focusing 
            on clean layouts, bold visuals, and responsive design. The page featured 
            interactive elements like smooth scrolling and hover effects, along with basic 
            SEO enhancements. The result aligned with Fold’s brand while providing a strong 
            platform for user engagement." 
          />
        </div>
        <div className="w-full md:w-1/2 mt-8 max-h-[500px] overflow-y-auto relative scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
          <img src="/assets/fold.webp" alt="Image 1" className="w-full object-cover" loading="lazy" />
          
        </div>
      </div>

      

      {/* <div className="flex flex-col md:flex-row mb-12">
        <div className="w-full md:w-1/2 p-6 flex flex-col justify-center">
          <DropdownSection 
            title="Section 3" 
            description="Description for section 3..." 
          />
        </div>
        <div className="w-full md:w-1/2">
          <img src="/path-to-image-3.jpg" alt="Image 3" className="w-full h-full object-cover" loading="lazy" />
        </div>
      </div> */}
      <div className="fixed bottom-0 left-0 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default WebDesign;

